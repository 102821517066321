import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import Beautiful from '../../components/Beautiful'
import GridSecundario from '../../components/GridSecundario'
import FormTipoAtividade from '../../components/FormTipoAtividade'
import DualListBox from "react-dual-listbox";
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import { useParams } from 'react-router-dom';
import DualListBoxPesonalizado from '../../components/DualListBoxPesonalizado'

function ActivityEdit() {
	const navigate = useNavigate();
	let { id } = useParams();
	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)

	const [idActive, setIdActive] = useState('')
	const [description, setDescription] = useState('')
	const [integrationid, setIntegrationid] = useState('')
	const [active, setActive] = useState(true)
	const [aty_id, setAty_id] = useState('')
	const [executiontype, setExecutiontype] = useState('')
	const [displayorder, setDisplayorder] = useState('')
	const [impeditiveactivity, setImpeditiveactivity] = useState('')
	const [datetimeinsert, setDatetimeinsert] = useState('')
	const [datetimeupdate, setDatetimeupdate] = useState('')
	const [dadosMobileinventoryimpediment, setDadosMobileinventoryimpediment] = useState([])
	const [optionsTipoAtividade, setOptionsTipoAtividade] = useState([])
	const [optionsAtividade, setOptionsAtividade] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)
	const [habilitar, setHabilitar] = useState('')
	const [selected, setSelected] = useState([]);
	const [optionFieldtype, setOptionFieldtype] = useState([])
	const [listaCampos, setListaCampos] = useState([])
	const [userSelecionada, setUserSelecionada] = useState([]);
	const [optionsActivity, setOptionsActivity] = useState([])
	const [tipoUser, setTipoUser] = useState('')
	const [mode, setMode] = useState('')
	const [recurringitems, setRecurringitems] = useState(false)
	const [compBeautiful, setCompBeautiful] = useState(<></>)

	const [descriptionDisabled, setDescriptionDisabled] = useState(false)
	const [integrationidDisabled, setIntegrationidDisabled] = useState(false)
	const [aty_idDisabled, setAty_idDisabled] = useState(false)
	const [executiontypeDisabled, setExecutiontypeDisabled] = useState(false)
	const [displayorderDisabled, setDisplayorderDisabled] = useState(false)
	const [dualListBoxDisabled, setDualListBoxDisabled] = useState(false)
	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [activeDisabled, setActiveDisabled] = useState(false)
	const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)
	const [recurringitemsDisabled, setRecurringitemsDisabled] = useState(false)

	const [descriptionIni, setDescriptionIni] = useState('')
	const [integrationidIni, setIntegrationidIni] = useState('')
	const [meusCampos, setMeusCampos] = useState([])
	const [compDualListBoxPesonalizado, setCompDualListBoxPesonalizado] = useState(<></>)
	const [optionItensSelecionados, setOptionItensSelecionados] = useState([])
	const [optionItens, setOptionItens] = useState([])

	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		validatToken();
		setActiveDisabled(true)
	}, []);

	function PegarValorGrid(valor: any) {

		setAty_id(String(valor.aty_id))
		setOpenModalIncluirNovo(false)

	}

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				

				if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {
					setTipoUser(ret.data.decodedToken.acp_integrationid)
					setDescriptionDisabled(false)
					setIntegrationidDisabled(false)
					setAty_idDisabled(false)
					setExecutiontypeDisabled(false)
					setDisplayorderDisabled(false)
					setDualListBoxDisabled(false)
					setActiveDisabled(false)
					setConfirmarUserDisabled(false)
					setCancelarDisabled(false)
					setRecurringitemsDisabled(false)
				} else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {
					setTipoUser(ret.data.decodedToken.acp_integrationid)
					setDescriptionDisabled(true)
					setIntegrationidDisabled(true)
					setAty_idDisabled(true)
					setExecutiontypeDisabled(true)
					setDisplayorderDisabled(true)
					setDualListBoxDisabled(true)
					setActiveDisabled(true)
					setConfirmarUserDisabled(true)
					setCancelarDisabled(true)
					setRecurringitemsDisabled(true)
				}

				await mobileactivityeditar();

			}

		} catch (e: any) {

			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}

	}



	async function mobileactivityeditar() {
		let ret: AxiosResponse;
		let valor: any;

		if (/^\d+$/.test(id || '') === true) {
			try {
				ret = await api.get("mobileactivity/mobileactivityeditar", {
					params: {
						id: id
					}
				})
				if (ret.data.status === 200) {

					valor = ret.data.data.listarmobileactivity[0]

					updateActivityState(valor);

					setOptionsActivity(ret.data.data.exibirmobileactivity)

					await GetTipoAtividade();
					await GetFieldtype();
					await GetAtividade();
					await GetItens(valor.act_mode, ret.data.data.listarItensSel)

					if (ret.data.data.listarmobileinventoryimpediment.length > 0) {

						const newarray = ret.data.data.listarmobileinventoryimpediment.map((element: any) => {
							return String(element.act_idimpediment)
						})

						setSelected(newarray)
					}


					if (ret.data.data.listarmobileactivityfields.length > 0) {


						const arr = [] as any;
						const arr2 = [] as any;

						ret.data.data.listarfieldtype.map((user: any) => {
							return arr.push({
								value: String(user.fte_id),
								label: user.fte_description,
								icon: user.fte_icon
							});
						});


						setCompBeautiful(<Beautiful
							MeusCampos={ret.data.data.listarmobileactivityfields}
							HandleOnDragEnd={HandleOnDragEnd}
							OptionFieldtype={arr}
							IncluirCampos={incluirCampos}
							OnDeleteCampos={OnDeleteCampos}
						/>)


					}



					if(valor.act_id === 1 || valor.act_id === 2 || valor.act_id === 3 
						|| valor.act_id === 4 || valor.act_id === 5){
							

						setDescriptionDisabled(true)
						setIntegrationidDisabled(true)
						setAty_idDisabled(true)
						setExecutiontypeDisabled(true)
						setDisplayorderDisabled(true)
						setDualListBoxDisabled(true)
						setActiveDisabled(true)
						setRecurringitemsDisabled(true)

					}


					

				} else {
					setOpenModal(false)
					navigate('/activity/edit/' + id + '/n');
				}
			} catch (e) {
				setOpenModal(false)
				navigate('/activity/edit/' + id + '/n');
			}

		} else {
			setOpenModal(false)
			navigate('/activity/edit/' + id + '/n');
		}

	}

	async function GetItens(modo: any, data: any) {


		if (modo === 2) {


			let ret: AxiosResponse;
			let valor: any;
			try {
				ret = await api.get("item/listar")
				if (ret.data.status === 200) {
					valor = ret.data.data;


					const arr = [] as any;
					const arr2 = [] as any;

					let result = valor;
					let result2 = data;

					result.map((user: any) => {
						return arr.push({
							id: String(user.ite_id),
							label: user.ite_description,
							ite_alternativeidentifier: user.ite_alternativeidentifier || '',
							its_id: user.its_id || '',
							ite_tag: user.ite_tag || '',
						});
					});

					result2.map((user: any) => {
						return arr2.push({
							id: String(user.ite_id),
							label: user.ite_description,
							ite_alternativeidentifier: user.ite_alternativeidentifier || '',
							its_id: user.its_id || '',
							ite_tag: user.ite_tag || '',
						});
					});

					const filteredArray = arr.filter((item1: any) =>
						!arr2.some((item2: any) => item1.id === item2.id)
					);



					setOptionItens(arr)

					setOptionItensSelecionados(arr2)

					setCompDualListBoxPesonalizado(<>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">4</div>
								<h2>Itens</h2>
							</div>
							<hr />
						</div>
						<DualListBoxPesonalizado
							items={filteredArray}
							pegarItens={pegarItens}
							itemsSel={arr2}
						/>
					</>)


				}
			} catch (e) {
				setOptionItens([])
			}






		} else {
			let ret: AxiosResponse;
			let valor: any;
			try {
				ret = await api.get("item/listar")
				if (ret.data.status === 200) {
					valor = ret.data.data;


					const arr = [] as any;
					let result = valor;
					result.map((user: any) => {
						return arr.push({
							id: String(user.ite_id),
							label: user.ite_description,
							ite_alternativeidentifier: user.ite_alternativeidentifier || '',
							its_id: user.its_id || '',
							ite_tag: user.ite_tag || '',
						});
					});

					setOptionItens(arr)

				}
			} catch (e) {
				setOptionItens([])
			}
		}

	}


	function updateActivityState(valor: any) {


		setActive(valor.act_active != undefined ? Boolean(valor.act_active) : true)
		setRecurringitems(valor.act_recurringitems != undefined ? Boolean(valor.act_recurringitems) : true);
		setDescription(valor.act_description || '');
		setDescriptionIni(valor.act_description || '');
		setIntegrationid(valor.act_integrationid || '');
		setIntegrationidIni(valor.act_integrationid || '');
		setAty_id(valor.aty_id || '');
		setExecutiontype(valor.act_executiontype || '');
		setMode(valor.act_mode || '');
		setDisplayorder(valor.act_displayorder || '');
		setDatetimeinsert(valor.act_datetimeinsert.substring(0, 16) || '');
		setDatetimeupdate(valor.act_datetimeupdate.substring(0, 16) || '');
		setOpenModal(false)
	}


	async function GetAtividade() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("mobileactivity/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				const arr = [] as any;
				let result = valor;

				const filtrarAtv = result.filter(
					(e: any) => {
						return String(e.act_id) !== id
					}
				);

				const ativos = filtrarAtv.filter(
                    (e: any) => {
                        return e.act_active === 1
                    }
                );


				ativos.map((user: any) => {
					return arr.push({ value: String(user.act_id), label: user.act_description });
				});
				setOptionsAtividade(arr)

			}
		} catch (e) {
			setOptionsAtividade([])
		}
	}

	function fecharModal2() {
		setOpenModal(false)
	}

	function fecharModalToken() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}

	function incluirCampos(dados: any) {
		setListaCampos(dados as any);
	}

	function OnDeleteCampos(id: any) {

		const newArray = listaCampos.filter((transaction: any) => transaction.id !== id);
		setListaCampos(newArray);

	};

	function HandleOnDragEnd(result: any) {

		setListaCampos(result);


	}

	async function GetFieldtype() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("fieldtype/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;

				result.map((user: any) => {
					return arr.push({ value: String(user.fte_id), label: user.fte_description, icon: user.fte_icon });
				});
				setOptionFieldtype(arr)

			}
		} catch (e) {
			setOptionFieldtype([])
		}
	}

	async function GetTipoAtividade() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("mobileactivitytype/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.aty_id), label: user.aty_description });
				});
				setOptionsTipoAtividade(arr)

			}
		} catch (e) {
			setOptionsTipoAtividade([])
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	function Valitarrecurringitems() {
		let e = document.getElementById("checkbox-recurringitems") as HTMLInputElement;

		if (e.checked) {

			setRecurringitems(true)
		} else {

			setRecurringitems(false)
		}
	}

	const tipoExec = [{
		value: 1,
		label: 'Finalizar Atividade'
	},
	{
		value: 2,
		label: 'Finalizar Tarefa'
	},
	{
		value: 3,
		label: 'Manter Tarefa no PDA'
	}
	]

	const modoatv = [{
		value: 1,
		label: 'Padrão'
	},
	{
		value: 2,
		label: 'Itens da Atividade'
	},
	{
		value: 3,
		label: 'Itens da Tarefa'
	},
	]

	function fecharModal() {
		setOpenModal(false)
	}

	function fecharPag() {
		navigate('/activity');
	}

	async function confirmarUser() {

		let ret: AxiosResponse;
		let valor: any;

		setOpenModal(true)
		setQualAlert(<div className='div-alert'><Loading /></div>)

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				const FiltrarDescricao = optionsActivity.filter(
					(e: any) => {
						return e.act_description.toLowerCase() === description.toLowerCase()
					}
				);

				const FiltrarIntegrationid = optionsActivity.filter(
					(e: any) => {
						return e.act_integrationid.toLowerCase() === integrationid.toLowerCase()
					}
				);

				if (description === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
					setOpenModal(true)

				} else if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {


					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (integrationid === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o ID para Integração.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)
					setOpenModal(true)

				} else if (integrationidIni.toLowerCase() !== integrationid.toLowerCase() && FiltrarIntegrationid.length > 0) {


					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">ID para Integração já está em uso.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (executiontype === '') {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Selecione a forma de execução.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)
					setOpenModal(true)

				} else if (mode === '') {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Selecione o modo.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)
					setOpenModal(true)

				}
				else if (String(mode) === '2' && optionItensSelecionados.length === 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Você precisa vincular ao menos um item a atividade.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)
					setOpenModal(true)

				}
				else if (listaCampos.length === 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Você precisa vincular ao menos um campo a atividade.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)
					setOpenModal(true)

				} else {

					

					const dados = {
						act_description: description,
						act_integrationid: integrationid,
						act_active: active,
						aty_id: aty_id,
						act_displayorder: displayorder,
						act_executiontype: executiontype,
						age_id_lastupdate: secureLocalStorage.getItem('iag'),
						act_mode: mode,
						act_id: id,
						act_recurringitems: recurringitems
					};

			

					try {
						ret = await api.put("mobileactivity/update", {
							data: dados,
							dadosimpediment: selected,
							dadoscampos: listaCampos,
							dadositens: optionItensSelecionados
						});
						if (ret.data.status === 200) {

							setIdActive(ret.data.data[0].act_id)

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
								<div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso!</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)

						}
					} catch (e: any) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
							<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
						</div>)

					}
				}
			}

		} catch (e: any) {

			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}


	}

	function finalizarModal1() {
		navigate('/activity');
	}


	function HabilitarFunc() {
		setOpenModal(false)
		setHabilitar('S')
	}

	function cancelar() {
		navigate('/activity');
	}

	function handleChange(selectedOption: any) {
		setSelected(selectedOption)
	};


	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};


	//-------------------------TIPO ATIVIDADE---------------------------------------------

	async function ChamarModalmobileactivitytype() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				if (tipoUser === 'DESENVOLVEDOR' || tipoUser === 'ADMINISTRADOR') {
					setOpenModalIncluirNovo(true)

					sessionStorage.removeItem("dadosTipoAtividade");
					setQualTitulo('Cadastrar Tipo')
					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastromobileactivitytype} Iconeditar={Editarmobileactivitytype} iconeExcluir={mobileactivitytypeDeletar}
						th1='Ativo'
						th2='Descrição'
						th3='Identificador Alternativo'
						th4='Subcategoria'
						th5='Data da Inclusão'
						th6='Data Atualização'
						endPoint='mobileactivitytype/listar'
						consult='N'
						col1='3'
						col2='2'
						col3='1'
						ambiente='%'
						dbtnDelete='N'
						dbtnAdd='N'
					/></>)
					)

				}


			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)

			}
		}


	}


	function finalizarModal2() {
		setOpenModal(false)
	}

	

	function valor(selected: any) {
		setSelected(selected)
	}

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	function incluirModo(valor: any) {
		setMode(valor)
		if (valor === 2) {
			setCompDualListBoxPesonalizado(<>
				<div className="title-Page">
					<div className="title-text">
						<div className="size-x circle">4</div>
						<h2>Itens</h2>
					</div>
					<hr />
				</div>
				<DualListBoxPesonalizado
					items={optionItens}
					pegarItens={pegarItens}
					itemsSel={[]}
				/>
			</>)
		} else {
			setCompDualListBoxPesonalizado(<></>)
			setOptionItensSelecionados([])
		}
	}

	function pegarItens(data: any) {
		setOptionItensSelecionados(data)
	}




















	function incluirGrupoTask(){
        setQualTitulo('Tipo')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid2}
          Iconeadd={ChamarCadastromobileactivitytype}
          Iconeditar={Editarmobileactivitytype}
          iconeExcluir={mobileactivitytypeDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='mobileactivitytype/buscar'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)
  
        setOpenModalIncluirNovo(true)
        sessionStorage.removeItem("dadosFab");
		GetTipoAtividade()

    }

//=================================grupo de tarefas=============================================
function ChamarCadastromobileactivitytype() {
    sessionStorage.removeItem("dadosTipoAtividade");
    setQualTitulo('Tipo')
    setQualGrid(<><FormTipoAtividade voltar={BtnVoltarmobileactivitytype} /></>)
  }

  async function Editarmobileactivitytype(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosTipoAtividade", JSON.stringify(obj));
      setQualTitulo('Tipo')
      setQualGrid(<><FormTipoAtividade voltar={BtnVoltarmobileactivitytype} /></>)
    }
  }

  function mobileactivitytypeDeletar(obj: any) {

    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarmobileactivitytype(obj.tgp_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }


  async function deletarmobileactivitytype(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("correctivesubgroup/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Tipo')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid2}
          Iconeadd={ChamarCadastromobileactivitytype}
          Iconeditar={Editarmobileactivitytype}
          iconeExcluir={mobileactivitytypeDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
           endPoint='mobileactivitytype/buscar'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)

      }
    } catch (e: any) {
      setQualTitulo('Tipo')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid2}
        Iconeadd={ChamarCadastromobileactivitytype}
        Iconeditar={Editarmobileactivitytype}
        iconeExcluir={mobileactivitytypeDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
         endPoint='mobileactivitytype/buscar'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

    }
  }

  function BtnVoltarmobileactivitytype() {
    setQualTitulo('Tipo')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid2}
      Iconeadd={ChamarCadastromobileactivitytype}
      Iconeditar={Editarmobileactivitytype}
      iconeExcluir={mobileactivitytypeDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
       endPoint='mobileactivitytype/buscar'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)
	GetTipoAtividade();
  }

  //==============================================================================

  function PegarValorGrid2(valor: any){
    GetTipoAtividade()
    setAty_id(String(valor.aty_id))
    setOpenModalIncluirNovo(false)
    }


	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>



						<div className="container-cad-prod40">
							<div className="class-checkbox">
								<input type="checkbox"
									id="checkbox-active"
									checked={active}
									onChange={ValitarActive}
									disabled={activeDisabled} />
								<h4 className="lab-ativo">Ativo</h4>

							</div>
							<div className="class-checkbox">
								<input type="checkbox"
									id="checkbox-recurringitems"
									checked={recurringitems}
									onChange={Valitarrecurringitems}
									disabled={recurringitemsDisabled} />
								<h4 className="lab-ativo">Permitir executar item mais de uma vez</h4>

							</div>

						</div>

						<div className="container-cad-prod39">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										type="text"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
										autoComplete="nope"
										disabled={descriptionDisabled}
									/>

								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">ID para Integração *</span>
									<input
										type="text"
										onChange={(e) => setIntegrationid(e.target.value)}
										value={integrationid}
										autoComplete="nope"
										disabled={integrationidDisabled}
									/>
								</div>
							</div>

							<div id="content3">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
									<div className='details-flex'>
                                        <span className="details">Tipo *</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
										<span
										className={`details-esc-ativo ${activeDisabled ? "disabled" : ""}`}
										onClick={!activeDisabled ? incluirGrupoTask : undefined}
										>
										Incluir Novo
										</span>
                                    </div>

									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsTipoAtividade}
										styles={customStyles}
										value={optionsTipoAtividade.filter(function (option: any) {
											return option.value === aty_id;
										})}
										onChange={(options: any) =>
											!options ? setAty_id('') : setAty_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={aty_idDisabled}
									/>
								</div>
							</div>

							<div id="content4">
								<div className="select-box-ativos-select">
									<span className="details">Forma de Execução *</span>
									<Select
										placeholder={'Selecione...'}
										options={tipoExec}
										styles={customStyles}
										value={tipoExec.filter(function (option: any) {
											return option.value === executiontype;
										})}
										onChange={(options: any) =>
											!options ? setExecutiontype('') : setExecutiontype(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={executiontypeDisabled}
									/>
								</div>
							</div>

						</div>

						<div className="container-cad-dash1-act">
							<div id="content1">
								<div className="select-box-ativos-select">
									<span className="details">Modo *</span>
									<Select
										placeholder={'Selecione...'}
										options={modoatv}
										styles={customStyles}
										value={modoatv.filter(function (option: any) {
											return option.value === mode;
										})}
										onChange={(options: any) =>
											!options ? incluirModo('') : incluirModo(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={executiontypeDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Ordem de Exibição</span>
									<input
										type="text"
										onChange={(e) => setDisplayorder(maskOnlyNumbers(e.target.value))}
										value={displayorder}
										autoComplete="nope"
										disabled={displayorderDisabled}
									/>

								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Data da Inclusão</span>
									<input
										type="datetime-local"
										value={datetimeinsert}
										disabled
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Data da Última Atualização</span>
									<input
										type="datetime-local"
										value={datetimeupdate}
										disabled
									/>
								</div>
							</div>

						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Atividades Impeditivas</h2>
							</div>
							<hr />
						</div>

						<div className='DualListBox'>
							<div className="input-box">
								<span className="details-list">ATIVIDADES DISPONÍVEIS:</span>
							</div>
							<DualListBox
								disabled={dualListBoxDisabled}
								options={optionsAtividade}
								selected={selected}
								onChange={valor}
								icons={{
									moveLeft: "<",
									moveAllLeft: "<<",
									moveRight: ">",
									moveAllRight: ">>"
								}}
								preserveSelectOrder={true}
							/>
						</div>


						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">3</div>
								<h2>Campos da Atividade</h2>
							</div>
							<hr />
						</div>


						{compBeautiful}

						{compDualListBoxPesonalizado}

						<div className="Bt-button-cad">
							<button type="submit" onClick={confirmarUser} disabled={confirmarUserDisabled}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>
					</div>
				</main>
			</div>


			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
						<div onClick={fecharOpenModalIncluirNovo}>
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>

					<div className='scrol-modal'>

						{qualGrid}

					</div>
				</>
			</ModalIncluirNovo>

			<ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalAlert>


		</>
	);

}

export default ActivityEdit;