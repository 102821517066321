import React, { useState, useEffect } from "react";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "./index.css";
import logomenu from "../../img/LOGOPADRAO.png";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router";
import {
  FaChartArea, FaUser, FaUsers,
  FaClipboardList, FaCalendarAlt,
  FaChevronDown, FaWindowRestore, FaCity, FaList, FaExchangeAlt, FaFileAlt,
  FaLayerGroup, FaFileInvoice
} from "react-icons/fa";
import { FaMobileScreen, FaListCheck, FaBarcode } from "react-icons/fa6";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { MdExitToApp } from "react-icons/md";
import { FaUsersGear } from "react-icons/fa6";
import { FaBoxes } from "react-icons/fa";
import { BsCalendarRangeFill } from "react-icons/bs";
import { RiUserSettingsFill } from "react-icons/ri";

interface Permission {
  age_id: number;
  age_name: string;
  acp_description: string;
  feature_name: string;
  prm_create: number;
  prm_view: number;
  prm_edit: number;
  prm_delete: number;
}

const Nav = () => {
  const navigate = useNavigate();
  const [sucessoM, setSucessoM] = useState("S");
  const [sucesso, setSucesso] = useState("S");
  const [acp, setAcp] = useState<Permission[]>([]);
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const storedPermissions = secureLocalStorage.getItem("pms") as string;


    if (storedPermissions) {
      const permissions: Permission[] = JSON.parse(storedPermissions);

      setAcp(permissions);
    }

    const logoFromStorage = secureLocalStorage.getItem("logo") as string;

    const image = new Image();
    image.src = logoFromStorage;

    image.onload = () => setLogo(logoFromStorage);
    image.onerror = () => setLogo(null);
  }, []);

  const navDropdownM = () => {
    setSucessoM(sucessoM === "N" ? "S" : "N");
  };

  function navDropdown() {
    if (sucesso === 'N') {
      setSucesso('S')
    } else {
      setSucesso('N')
    }
  }

  const handleLogoff = () => {
    secureLocalStorage.removeItem("t");
    secureLocalStorage.removeItem("acp");
    secureLocalStorage.removeItem("n");
    secureLocalStorage.removeItem("iag");
    secureLocalStorage.removeItem("endPoint");

    navigate("/login");
  };

  // Função para verificar permissões
  const hasPermission = (feature: string) => {
    return acp.some((permission) => permission.feature_name === feature && permission.prm_view === 1);
  };

  return (
    <>
      <input type="checkbox" id="nav-toggle" />
      <div className="sidebar">
        <div className="sidebar-brand">
          <img className="img-dash2" src={logo || logomenu} alt="Logo" />
        </div>

        <div className="sidebar-menu">
          <ul>
            {hasPermission("Dashboard") && (
              <li className="side">
                <Link to='/dashboard'>
                  <span className='ControleIcon'><FaChartArea /></span> <span className="lab-icone">Dashboard</span>
                </Link>
                <h1 className="Legenda-side">Dashboard</h1>
              </li >
            )}

            {hasPermission("Usuários") && (
              <li className="side">
                <Link to="/user">
                  <span className="ControleIcon"><FaUser /></span>
                  <span className="lab-icone">Usuários</span>
                </Link>
                <h1 className="Legenda-side">Usuários</h1>
              </li>
            )}

            {hasPermission("Mobile") && (
              <div className="side-dropdown">
                <li className="side">
                  <a onClick={navDropdownM}>
                    <span className='ControleIcon'><FaMobileScreen /></span>
                    <span className="lab-icone2">Mobile</span>
                    <FaChevronDown className='icone-navDropdown2' />
                  </a>
                  <div className='Legenda-Dropdown'>

                    {hasPermission("Equipes") && (
                      <Link to="/team">
                        <span className='ControleIco2'><FaUsers /></span>
                        <h1>Equipes</h1>
                      </Link>
                    )}

                    {hasPermission("Atividades") && (
                      <Link to="/activity">
                        <span className='ControleIco2'><FaClipboardList /></span>
                        <h1>Atividades</h1>
                      </Link>
                    )}

                    {hasPermission("Itens da Atividade") && (
                      <Link to="/item">
                        <span className='ControleIco2'><FaBoxes /></span>
                        <h1>Itens</h1>
                      </Link>
                    )}

                    {hasPermission("Planos Automáticos") && (
                      <Link to="/planner">
                        <span className='ControleIco2'><FaCalendarAlt /></span>
                        <h1>Planos Automáticos</h1>
                      </Link>
                    )}

                    {hasPermission("Calendário Atividades") && (
                      <Link to="/activitycalendar">
                        <span className='ControleIco2'><BsCalendarRangeFill /></span>
                        <h1>Calendário Atividades</h1>
                      </Link>
                    )}

                    {hasPermission("Cadastros Customizados") && (
                      <Link to="/customregistrations">
                        <span className='ControleIco2'><FaListCheck /></span>
                        <h1>Cadastros Customizados</h1>
                      </Link>
                    )}

                  </div>
                </li >
                {sucessoM === 'N' ?
                  <div className='dropdown-background'>

                    {hasPermission("Equipes") && (
                      <div className="nav__dropdown-content">
                        <Link to="/team">
                          <span className='ControleIco'><FaUsers /></span>
                          <span className="lab-iconeeee">Equipes</span>
                        </Link>
                      </div>
                    )}

                    {hasPermission("Atividades") && (
                      <div className="nav__dropdown-content">
                        <Link to="/activity">
                          <span className='ControleIco'><FaClipboardList /></span>
                          <span className="lab-iconeeee">Atividades</span>
                        </Link>
                      </div>
                    )}

                    {hasPermission("Itens da Atividade") && (
                      <div className="nav__dropdown-content">
                        <Link to="/item">
                          <span className='ControleIco'><FaBoxes /></span>
                          <span className="lab-iconeeee">Itens</span>
                        </Link>
                      </div>
                    )}

                    {hasPermission("Planos Automáticos") && (
                      <div className="nav__dropdown-content">
                        <Link to="/planner">
                          <span className='ControleIco'><FaCalendarAlt /></span>
                          <span className="lab-iconeeee">Planos Automáticos</span>
                        </Link>
                      </div>
                    )}

                    {hasPermission("Calendário Atividades") && (
                      <div className="nav__dropdown-content">
                        <Link to="/activitycalendar">
                          <span className='ControleIco'><BsCalendarRangeFill /></span>
                          <span className="lab-iconeeee">Calendário Atividades</span>
                        </Link>
                      </div>
                    )}

                    {hasPermission("Cadastros Customizados") && (
                      <div className="nav__dropdown-content">
                        <Link to="/customregistrations">
                          <span className='ControleIco'><FaListCheck /></span>
                          <span className="lab-iconeeee">Cadastros Customizados</span>
                        </Link>
                      </div>
                    )}

                  </div>

                  : null}
              </div>
            )}
            {hasPermission("Produto") && (
              <li className="side">
                <Link to="/product">
                  <span className="ControleIcon"><FaBarcode /></span>
                  <span className="lab-icone">Produto</span>
                </Link>
                <h1 className="Legenda-side">Produto</h1>
              </li>
            )}
            {hasPermission("Ativos") && (
              <div className="side-dropdown">
                <li className="side">
                  <a onClick={navDropdown}><span className='ControleIcon'><FaWindowRestore /></span>
                    <span className="lab-icone">Ativos</span>
                    <FaChevronDown className='icone-navDropdown3' /></a>
                  <div className='Legenda-Dropdown'>
                    {hasPermission("Cadastros Ativo") && (
                      <Link to="/visualizar"><span className='ControleIco2'><FaLayerGroup /></span> <h1>Cadastros</h1> </Link>
                    )}
                  
                  </div>
                </li >
                {sucesso === 'N' ?
                  <div className='dropdown-background'>

                    {hasPermission("Cadastros Ativo") && (
                      <div className="nav__dropdown-content">
                        <Link to="/asset"><span className='ControleIco'><FaLayerGroup /></span> <span className="lab-iconeeee">Cadastros</span> </Link>
                      </div>
                    )}
                  </div>

                  : null}
              </div>
            )}
            {hasPermission("Locais") && (
              <li className="side">
                <Link to="/local">
                  <span className="ControleIcon"><FaCity /></span>{" "}
                  <span className="lab-icone">Locais</span>
                </Link>
                <h1 className="Legenda-side">Locais</h1>
              </li>
            )}
            {hasPermission("Terceiros") && (
              <li className="side">
                <Link to="/serviceproviders">
                  <span className="ControleIcon"><FaUsersGear /></span>{" "}
                  <span className="lab-icone">Terceiros</span>
                </Link>
                <h1 className="Legenda-side">Terceiros</h1>
              </li>
            )}
            {hasPermission("Peças e Materiais") && (
              <li className="side">
                <Link to="/partmaterial">
                  <span className="ControleIcon"><FaScrewdriverWrench /></span>{" "}
                  <span className="lab-icone">Peças e Materiais</span>
                </Link>
                <h1 className="Legenda-side">Peças e Materiais</h1>
              </li>
            )}
            {hasPermission("Serviços") && (
              <li className="side">
                <Link to="/services">
                  <span className="ControleIcon"><RiUserSettingsFill /></span>{" "}
                  <span className="lab-icone">Serviços</span>
                </Link>
                <h1 className="Legenda-side">Serviços</h1>
              </li>
            )}
            {hasPermission("Notas Fiscais") && (
              <li className="side">
                <Link to="/invoice">
                  <span className="ControleIcon"><FaFileInvoice /></span>{" "}
                  <span className="lab-icone">Notas Fiscais</span>
                </Link>
                <h1 className="Legenda-side">Notas Fiscais</h1>
              </li>
            )}
            {hasPermission("Meus Cadastros") && (
              <li className="side">
                <Link to="/customentity">
                  <span className="ControleIcon"><FaList /></span>{" "}
                  <span className="lab-icone">Meus Cadastros</span>
                </Link>
                <h1 className="Legenda-side">Meus Cadastros</h1>
              </li>
            )}
            {hasPermission("Movimentações") && (
              <li className="side">
                <Link to='/movement'><span className='ControleIcon'><FaExchangeAlt /></span>
                  <span className="lab-icone">Movimentações</span></Link>
                <h1 className="Legenda-side">Movimentações</h1>
              </li>
            )}
            {hasPermission("Tarefas") && (

              <li className="side">
                <Link to='/task'><span className='ControleIcon'><FaFileAlt /></span>
                  <span className="lab-icone">Tarefas</span></Link>
                <h1 className="Legenda-side">Tarefas</h1>
              </li>
            )}

            <li className="side" onClick={handleLogoff}>
              <a>
                <span className="ControleIcon"><MdExitToApp /></span>
                <span className="lab-icone">Sair</span>
              </a>
              <h1 className="Legenda-side">Sair</h1>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Nav;
